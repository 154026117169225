@import '~antd/dist/antd.less';

@desktop: ~'only screen and (min-width: 800px)'; // 👻 check synchro with isMobileWindow in js
@mobile: ~'only screen and (max-width: 799px)'; // ☃️ check synchro with isMobileWindow  in js

@light-grey: #c5c7cb;

@drawer-desktop-width: 333px;
@drawer-background: #ffffff;

@media @mobile {
  input,
  select,
  textarea {
    font-size: 16px;
  }
  /deep/ .ant-input {
    font-size: 16px;
  }
}

.fallback-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

@primary-color: #e53f67;